import { createWebHistory, createRouter } from "vue-router";
import Home from "@/components/HomePage.vue";
import Work from "@/components/WorkPage.vue";
import About from "@/components/AboutPage.vue";

const routes = [{
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/work",
        name: "work",
        component: Work,
    },
    {
        path: "/about",
        name: "about",
        component: About,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    // eslint-disable-next-line
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 };
    }
});

export default router;
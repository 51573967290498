<template>
  <div>
    <div class="content-show">
      <div id="fixed-content">
        <div>
          <div
            id="background-1"
            v-bind:style="{
              opacity: 1,
              background:
                'linear-gradient(20deg,rgb(198, 135, 255),rgb(11, 168, 223))',
            }"
          ></div>
        </div>
        <div>
          <div class="headroom-wrapper" style="height: 50px">
            <div class="headroom headroom--unfixed">
              <div id="navbar">
                <div id="nav-background" class="nav-work"></div>
                <a class="text-link after-animation">W O R K</a>
                <router-link class="text-link" to="/">H O M E</router-link>
                <router-link class="text-link" to="/about"
                  >A B O U T</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div id="page-content">
          <div id="page-scroll-helper"></div>
          <div id="work-scroll-wrapper">
            <div class="vertical-line vertical-line-left"></div>
            <div class="vertical-line vertical-line-right"></div>
            <div id="mywork">
              <div class="work-title-container col">
                <div id="horizontal-line-work" class="horizontal-line"></div>
                <div class="work-title">
                  <h2 class="font-title">Some things I've worked on</h2>
                </div>
                <div id="horizontal-line-work" class="horizontal-line"></div>
              </div>
              <div class="work-content">
              <li class="project project-struct-right" v-scrollanimation>
                  <div class="project-content project-content-reverse">
                    <p class="project-overline font-focus">Featured Project</p>
                    <h3 class="project-title font-title">Diverse products @ CIBG</h3>
                    <div class="project-description">
                      <p>
                        At CIBG, I had the opportunity to work on a variety of products. 
                        These ranged from internal tools used by Farmatec to web applications designed for 
                        healthcare providers, facilitating digitization to make healthcare more efficient 
                        and accessible. It is a pleasure to work on applications that have a societal impact,
                        knowing that I am contributing to making the Netherlands increasingly advanced.
                      </p>
                    </div>
                    <ul class="project-tech-list">
                      <li class="font-focus">.NET</li>
                      <li class="font-focus">Angular</li>
                      <li class="font-focus">ElasticSearch</li>
                      <li class="font-focus">SonarCloud</li>
                      <li class="font-focus">EntityFramework</li>
                      <li class="font-focus">MSSQL</li>
                    </ul>
                  </div>
                  <div class="project-image">
                    <div class="project-image-overlay"></div>
                    <img src="../assets/CIBG.png" alt="" />
                  </div>
                </li>
                <li class="project project-struct-left" v-scrollanimation>
                  <div class="project-content">
                    <p class="project-overline font-focus">Featured Project</p>
                    <h3 class="project-title font-title">
                      Application Security
                    </h3>
                    <div class="project-description">
                      <p>
                        Evaluate, design and (re)build security mechanisms of
                        Private-Radar's SaaS flight management system. This has
                        been done using OWASP guidelines, including ASVS. The
                        main security measure was to rebuild the authentication
                        and authorization system along with brute force
                        prevention. This was done as a microservice that passed
                        integration tests, unit tests, functional tests, and
                        performance tests to ensure its correct functionality
                        and as it is used in the production environment.
                      </p>
                    </div>
                    <ul class="project-tech-list">
                      <li class="font-focus">OWASP</li>
                      <li class="font-focus">.NET 6</li>
                      <li class="font-focus">AWS</li>
                      <li class="font-focus">React</li>
                      <li class="font-focus">Serilog</li>
                      <li class="font-focus">JWT</li>
                    </ul>
                  </div>
                  <div class="project-image">
                    <div class="project-image-overlay"></div>
                    <img src="../assets/private-radar.png" alt="" />
                  </div>
                </li>
                <li class="project project-struct-right" v-scrollanimation>
                  <div class="project-content project-content-reverse">
                    <p class="project-overline font-focus">Featured Project</p>
                    <h3 class="project-title font-title">Integrated Webchat</h3>
                    <div class="project-description">
                      <p>
                        An integrated web chat for the meeting system of MVI
                        AudioVisual where meeting participants can securely
                        communicate with each. It uses end-to-end encryption
                        with a hybrid cryptosystem that combines asynchronous
                        and synchronous encryption making the messages
                        unreadable including for the server. The webchat is
                        fully customizable, including the styling and config
                        such as permissions, default group chats and more.
                      </p>
                    </div>
                    <ul class="project-tech-list">
                      <li class="font-focus">VueJS</li>
                      <li class="font-focus">ASP .NET</li>
                      <li class="font-focus">EntityFramework</li>
                      <li class="font-focus">SignalR</li>
                      <li class="font-focus">API</li>
                    </ul>
                  </div>
                  <div class="project-image">
                    <div class="project-image-overlay"></div>
                    <img src="../assets/webchat.png" alt="" />
                  </div>
                </li>
                <li class="project project-struct-left" v-scrollanimation>
                  <div class="project-content">
                    <p class="project-overline font-focus">Featured Project</p>
                    <h3 class="project-title font-title">Android Game</h3>
                    <div class="project-description">
                      <p>
                        A prototype of a serious game for android mobiles.
                        Through different storylines the game aims to teach
                        players interactively how to serve and help people going
                        through mental illnesses such as depression or anxiety.
                        This project was carried out by a multicultural and
                        multidisciplinary team with the help of experts in the
                        field of psychology.
                      </p>
                    </div>
                    <ul class="project-tech-list">
                      <li class="font-focus">Unity</li>
                      <li class="font-focus">C#</li>
                      <li class="font-focus">Figma</li>
                      <li class="font-focus">Maze testing</li>
                    </ul>
                  </div>
                  <div class="project-image">
                    <div class="project-image-overlay"></div>
                    <img src="../assets/those-around-us.png" alt="" />
                  </div>
                </li>
                <li class="project project-struct-right" v-scrollanimation>
                  <div class="project-content project-content-reverse">
                    <p class="project-overline font-focus">Featured Project</p>
                    <h3 class="project-title font-title">
                      Power Analyser logger
                    </h3>
                    <div class="project-description">
                      <p>
                        Fortop used a logger application to read power
                        analyzers, process it and store the data to be used to
                        visualize information such as consumption, quality of
                        the energy and more. Fortop's logger application was
                        outdated. Together with a group of 5 students, we built
                        a new system, using current technology, that outperforms
                        the old system in future-proofness, speed and
                        efficiency.
                      </p>
                    </div>
                    <ul class="project-tech-list">
                      <li class="font-focus">Rust</li>
                      <li class="font-focus">PostgreSQL</li>
                      <li class="font-focus">Modbus</li>
                      <li class="font-focus">Algorithms</li>
                      <li class="font-focus">Datastructures</li>
                    </ul>
                  </div>
                  <div class="project-image">
                    <div class="project-image-overlay"></div>
                    <img src="../assets/fortop.png" alt="" />
                  </div>
                </li>
              </div>
              <div class="copyright center-content"><span> Designed and coded by Santiago Rodriguez <span class="copy-symbol">©</span> 2022</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WorkPage",
  data() {
    return {};
  },
  async mounted() {},
  computed: {},
  methods: {},
};
</script>

<style scoped>
.project-struct-left.before-enter {
  transform: translateX(500px);
}

.project-struct-right.before-enter {
  transform: translateX(-500px);
}

.before-enter {
  opacity: 0;
  transition: all 2s cubic-bezier(0.68, 0.57, 0.01, 1.13);
  transition-delay: 0.5s;
}

.enter {
  opacity: 1 !important;
  transform: translateX(0px) !important;
}

@media (max-width: 450px) {
  .project-struct-left.before-enter {
    transform: translateX(300px);
  }

  .project-struct-right.before-enter {
    transform: translateX(-300px);
  }
}
</style>

<style src="../css/general-style.css"></style>
<style src="../css/workStyle.css"></style>